<template>
    <div id="shop-list">
      <!-- list filters -->
      <v-card>
        <v-card-title>Shops</v-card-title>
        <v-row class="px-2 ma-0">
          <!-- shop filter -->
          <v-col
            cols="12"
            sm="8"
          >
            <v-select
              v-model="shopFilter"
              placeholder="Select Shop"
              :items="shopFilterData"
              item-text="text"
              item-value="value"
              outlined
              dense
              clearable
              hide-details
            ></v-select>
          </v-col>
        </v-row>
  
        <v-divider class="mt-4"></v-divider>
  
        <!-- actions -->
        <v-card-text class="d-flex align-center flex-wrap pb-0">
          <!-- search -->
          <v-btn
            color="primary"
            class="mb-4 me-3"
            :loading="loading"
            @click.prevent="applyFilters"
          >
            Apply Filters
          </v-btn>
  
          <v-btn
            color="secondary"
            outlined
            class="me-3 mb-4"
            type="reset"
            @click.prevent="resetFilters"
          >
            Reset
          </v-btn>
        </v-card-text>
  
        <!-- table -->
        <v-data-table
          v-model="selectedRows"
          :headers="tableColumns"
          :items="listData"
          :search="searchQuery"
          :options.sync="options"
          :server-items-length="totalListData"
          :items-per-page="10"
          :loading="loading"
        >
          <!-- Shop -->
          <template #[`item.shopName`]="{ item }">
            <div class="d-flex align-center">
              <v-avatar
                :color="item.avatar ? '' : 'primary'"
                :class="item.avatar ? '' : 'v-avatar-light-bg primary--text'"
                size="32"
              >
                <span>{{ item.shopName.slice(0, 2).toUpperCase() }}</span>
              </v-avatar>
  
              <div class="d-flex flex-column ms-3">
                <span class="d-block font-weight-semibold text--primary text-truncate">{{ item.shopName }}</span>
              </div>
            </div>
          </template>


          <template #[`item.apiKey`]="{ item }">
            <div>
                <input
                    :type="isApiKeyVisible ? 'text' : 'password'"
                    :value="item.apiKey"
                    readonly
                    :class="{'hidden': !isApiKeyVisible}"
                />
                <v-icon
                    size="20"
                    class="ml-1 mr-3"
                    @click="isApiKeyVisible = !isApiKeyVisible"
                >
                    {{ isApiKeyVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline }}
                </v-icon>
                <v-icon
                    size="20"
                    @click="copyToClipboard(item.apiKey)"
                >
                    {{ icons.mdiContentCopy }}
                </v-icon>
             </div>
          </template>

          <template #[`item.secretKey`]="{ item }">
            <div>
                <input
                    :type="isSecretKeyVisible ? 'text' : 'password'"
                    :value="item.secretKey"
                    readonly
                    :class="{'hidden': !isSecretKeyVisible}"
                />
                <v-icon
                    size="20"
                    class="ml-1 mr-3"
                    @click="isSecretKeyVisible = !isSecretKeyVisible"
                >
                    {{ isSecretKeyVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline }}
                </v-icon>
                <v-icon
                    size="20"
                    @click="copyToClipboard(item.secretKey)"
                >
                    {{ icons.mdiContentCopy }}
                </v-icon>
            </div>
          </template>
  
          <!-- Status -->
          <template #[`item.statusCode`]="{ item }">
            <v-chip
              small
              :color="resolveStatusVariant(item.statusCode)"
              :class="`${resolveStatusVariant(item.statusCode)}--text`"
              class="v-chip-light-bg"
            >
              {{ item.statusCode }}
            </v-chip>
          </template>
 
        </v-data-table>
      </v-card>
      <v-toast ref="vtoast"></v-toast>
    </div>
  </template>
  
  <script>

// sidebar
  import {
    mdiEyeOffOutline, 
    mdiEyeOutline,
    mdiContentCopy,
  } from '@mdi/js'

  import {
    onMounted, onUnmounted,
  } from '@vue/composition-api'

  import { avatarText, formatDate, formatCurrency } from '@core/utils/filter'
  import store from '@/store'
  import apiCredentialsListStoreModule from './apiCredentialsListStoreModule'
  
  import useAPICredentialsList from './useAPICredentialsList'
  import VToast from '@/components/VToast.vue'
  
  export default {
    components: { 
        VToast,
    },
    setup() {
      const STORE_MODULE_NAME = 'developers-api-credentials'
  
      // Register module
      if (!store.hasModule(STORE_MODULE_NAME)) { store.registerModule(STORE_MODULE_NAME, apiCredentialsListStoreModule) }
  
      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule(STORE_MODULE_NAME)) { store.unregisterModule(STORE_MODULE_NAME) }
      })

      const {
        vtoast,
        listData,
        totalListData,
        tableColumns,
        searchQuery,
        shopFilter,
        shopFilterData,
        loading,
        options,
        selectedRows,
        fetchList,
        applyFilters,
        resetFilters,
        fetchConfig,
        resolveStatusVariant,

        isApiKeyVisible,
        isSecretKeyVisible,
        copyToClipboard,
      } = useAPICredentialsList()
  
      onMounted(() => {
        fetchConfig()
      })
  
      return {
        vtoast,
        listData,
        totalListData,
        tableColumns,
        searchQuery,
        shopFilter,
        shopFilterData,
        loading,
        options,
        selectedRows,
        avatarText,
        resolveStatusVariant,
        fetchList,
        applyFilters,
        resetFilters,
        fetchConfig,
        formatDate,
        formatCurrency,

        isApiKeyVisible,
        isSecretKeyVisible,
        copyToClipboard,
        // icons
        icons: {
          mdiEyeOffOutline, 
          mdiEyeOutline,
          mdiContentCopy,
  
        },
      }
    },
  }
  </script>
  
  <style lang="scss" scoped>
    @import '@core/preset/preset/finance/settlement.scss';
  </style>
  