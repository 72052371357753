import { ref, watch } from '@vue/composition-api'
import store from '@/store'
import shopStatus from '@/constants/shop-status'

export default function useAPICredentialsList() {
  const vtoast = ref({})
  const listData = ref([])
  const totalListData = ref(0)

  const tableColumns = [
    { text: 'CODE', value: 'shopCode', align: 'center' },
    { text: 'SHOP NAME', value: 'shopName' },
    { text: 'API KEY', value: 'apiKey', sortable: false },
    { text: 'SECRET KEY', value: 'secretKey', sortable: false },
    {
      text: 'STATUS', value: 'statusCode', align: 'center', sortable: false,
    },
  ]

  const searchQuery = ref('')
  const shopFilter = ref(null)
  const shopFilterData = ref([])

  const loading = ref(false)

  const options = ref({
    sortBy: ['id'],
    sortDesc: [true],
  })

  const selectedRows = ref([])
  const pageNumber = ref(1)
  const pageSize = ref(10)

  const isApiKeyVisible = ref(false)
  const isSecretKeyVisible = ref(false)

  const copyToClipboard = value => {
      navigator.clipboard.writeText(value)
      vtoast.value.show([{ message: `Copied to clipboard!`, code: '000' }], 'info')
  }

  // fetch data
  const fetchList = () => {
    loading.value = true

    store
      .dispatch('developers-api-credentials/fetchList', {
        q: searchQuery.value,
        options: options.value,
        shopId: shopFilter.value,
        pageNumber: pageNumber.value,
        pageSize: pageSize.value,
      })
      .then(response => {
        const { records, count } = response.data
        listData.value = records
        totalListData.value = count
      })
      .catch(error => {
        vtoast.value.show(error.response.data.errors, 'error')
      })
      .finally(() => {
        loading.value = false
      })
  }

  watch([options], () => {
    selectedRows.value = []
    pageNumber.value = options.value.page
    pageSize.value = options.value.itemsPerPage
    fetchList()
  })

  const applyFilters = () => {
    fetchList()
  }

  const resetFilters = () => {
    searchQuery.value = ''
    shopFilter.value = null
    pageNumber.value = 1
  }

  const fetchConfig = () => {
    loading.value = true
    store
      .dispatch('developers-api-credentials/fetchConfig')
      .then(response => {
        const config = response.data
        shopFilterData.value = config.shops
      })
      .catch(error => {
        vtoast.value.show(error.response.data.errors, 'error')
      })
      .finally(() => {
        loading.value = false
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*
  const resolveStatusVariant = status => {
    if (status === shopStatus.ENABLED) return 'success'
    if (status === shopStatus.DISABLED) return 'secondary'

    return 'primary'
  }

  return {
    vtoast,
    listData,
    totalListData,
    tableColumns,
    searchQuery,
    shopFilter,
    shopFilterData,
    loading,
    options,
    selectedRows,
    applyFilters,
    resetFilters,
    fetchList,
    fetchConfig,
    resolveStatusVariant,

    isApiKeyVisible,
    isSecretKeyVisible,
    copyToClipboard,
  }
}
